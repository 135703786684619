import axios from "axios";
import {APPLICATION_URL} from '../urlconfig';

let config = {

   
   CLIENTURL :{
     
   
      GET_JOB_INFO : "api/v1/jobs/get",
      SAVE_JOB_INFO :"api/v1/jobs/save",
      UPDATE_JOB_INFO:"api/v1/jobs/update",
      DELETE_JOB_INFO:"api/v1/jobs/delete",
      DELETE_USER_JD :"api/v1/userjobcode/deleteUserJD",
      CHECK_EXCLUSIVITY:"api/v1/userjobcode/checkexclusivity",
      CHECK_LOCK_JD :"api/v1/userjobcode/checkLockJD",
      JOB_DESCRIPTION :"api/v1/jobs/getjobdescriptions",
      GET_EMAIL_TEMPLATE_INFO :"api/v1/template/get",
      SAVE_EMAIL_TEMPLATE_INFO:"api/v1/template/save",
      UPDATE_EMAIL_TEMPLATE_INFO:"api/v1/template/update",
      DELETE_EMAIL_TEMPLATE_INFO:"api/v1/template/delete",
      JOB_QUERY_COUNT:"api/v1/jobs/jobcodequerycounts",
      JOB_CODE_FILTER:"api/v1/jobs/jobcodefilter",
      GET_OFFER : "api/v1/offer/get",
      SAVE_OFFER :"api/v1/offer/save",
      UPDATE_OFFER:"api/v1/offer/update",
      DELETE_OFFER:"api/v1/offer/delete",
      PREVIEW :"api/v1/userjobcode/preview",
      SAVE_EXCHANGE_NOTES :"api/v1/exchangenotes/save",
      GET_EXCHANGE_NOTES:"api/v1/exchangenotes/get",
      SAVE_INVOICE :"api/v1/invoice/save",
      GET_INVOICE :"api/v1/invoice/get",
      UPDATE_INVOICE :"api/v1/invoice/update",
      DASHBOARD_COUNT :"api/v1/content/dashboardcounts"

  
   }
      
     
  
  }

  export const dashboardCountInfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.DASHBOARD_COUNT, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        //window.location = "/";
        return error;
    });

}

  export const getInvoice =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.GET_INVOICE, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const updateInvoice =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.UPDATE_INVOICE, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}


  export const saveInvoice =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.SAVE_INVOICE, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
       // window.location = "/";
        return error;
    });

}

  export const getexchangenotes =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.GET_EXCHANGE_NOTES, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const saveexchangenotes =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.SAVE_EXCHANGE_NOTES, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const deleteofferInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.DELETE_OFFER, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const PreviewEmail =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.PREVIEW, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const deleteJobcode =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.DELETE_JOB_INFO, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const UpdateOffer =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.UPDATE_OFFER, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const SaveOffer =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.SAVE_OFFER, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}
  
  export const GetOffer =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.GET_OFFER, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}
 
  export const JobcodeFilter =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.JOB_CODE_FILTER, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const JobqueryCount =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.JOB_QUERY_COUNT, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const DeleteEmailTemplateInfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.DELETE_EMAIL_TEMPLATE_INFO, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const UpdateEmailTemplateInfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.UPDATE_EMAIL_TEMPLATE_INFO, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}

  export const SaveEmailTemplateInfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.SAVE_EMAIL_TEMPLATE_INFO, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });

}



  export const GetEmailTemplateInfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.GET_EMAIL_TEMPLATE_INFO, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const DeleteUserJD =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.DELETE_USER_JD, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const JobDescriptionInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.JOB_DESCRIPTION, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const checkJDLock =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.CHECK_LOCK_JD, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const SaveJobinformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.SAVE_JOB_INFO, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const UpdateJobinformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.UPDATE_JOB_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const GetJobinformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.CLIENTURL.GET_JOB_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}
