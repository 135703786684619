import React, { useEffect, useState } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteofferInformation, GetOffer } from '../jd/Job.service';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import EditOffer from './EditOffer';
import Swal from 'sweetalert2';
import moment from "moment";
import DateFilter from '../shared/DateFilter';
const { SearchBar } = Search;

function RecruiterOfferlist() {


   useEffect(() => {
      loadinformation();

   }, []);

   const [offersdata, setOffersdata] = useState([]);
   const [lgShow, setLgShow] = useState(false);
   const [pagetype, setPageType] = useState(null);
   const [pagetitle, setPageTitle] = useState(false);

   const loadinformation = async () => {
      localStorage.removeItem('offerrow');
      localStorage.removeItem('filtersave');

      const reqdata = {
         userid: sessionStorage.getItem('recruiterid'),
         fromdate: '',
         todate: ''
      }

      const recruiteroffers = await GetOffer(reqdata);
      const rowdata = [];
      let counter = 0;
      await recruiteroffers.data.reduce(async (promise, res) => {
         await promise;
         res.newcreateddate = moment(res.createddate).format('YYYY-MM-DD h:mm:ss a');
         rowdata.push(res);

         if (counter == recruiteroffers.data.length - 1) {
            rowdata.sort((x, y) => +new Date(y.jobcreatedate) - +new Date(x.jobcreatedate));
            setOffersdata(rowdata);
         }

         counter++;



      }, Promise.resolve());
   }

   const headers = [
      { label: "Job Code", key: "jobcode" },
      { label: "Offer Created Date", key: "newcreateddate" },
      { label: "Recruiter ID", key: "userid" },
      { label: "Client ID", key: "clientid" },
      { label: "Recruiter Name", key: "recruitername" },
      { label: "Client Name", key: "clientname" },
      { label: "JD Title", key: "jdtitle" },
      { label: "Candidate Name", key: "candidatename" },
      { label: "Designation", key: "designation" },
      { label: "Work Location", key: "worklocation" },
      { label: "Offered CTC", key: "offeredctc" },
      { label: "Singup Rate", key: "signuprate" },
      { label: "Base Invoice", key: "baseinvoiceval" },
      { label: "Offer Acccepted", key: "offeraccepted" },
      { label: "Date of Joining", key: "dateofjoining" },
      { label: "Resigned", key: "resigned" },
      { label: "User Incentive", key: "userincentive" },
      { label: "Joined", key: "joined" },
      { label: "User Comments & Remarks", key: "comments" }
   ]

   const columns = [
      {
         dataField: 'newcreateddate',
         text: 'Offer Created Date',
         sort: true
      },
      {
         dataField: 'jobcode',
         text: 'Job Code',
         sort: true
      }, {
         dataField: 'userid',
         text: 'Recruiter ID',
         sort: true
      },
      {
         dataField: 'clientid',
         text: 'Client ID',
         sort: true
      },
      {
         dataField: 'recruitername',
         text: 'Recruiter Name',
         sort: true
      },
      {
         dataField: 'clientname',
         text: 'Client Name',
         sort: true
      },
      {
         dataField: 'jdtitle',
         text: 'JD Title',
         sort: true
      },
      {
         dataField: 'candidatename',
         text: 'Candidate Name',
         sort: true
      },
      {
         dataField: 'designation',
         text: 'Designation',
         sort: true
      },
      {
         dataField: 'worklocation',
         text: 'Work Location',
         sort: true
      },
      {
         dataField: 'offeredctc',
         text: 'Offered CTC',
         sort: true
      },

      {
         dataField: 'signuprate',
         text: 'Signup Rate',
         sort: true
      },
      {
         dataField: 'baseinvoiceval',
         text: 'Base Invoice',
         sort: true
      },
      {
         dataField: 'offeraccepted',
         text: 'Offer Accepted',
         sort: true
      },
      {
         dataField: 'dateofjoining',
         text: 'Date of Joining',
         sort: true
      },
      {
         dataField: 'resigned',
         text: 'Resigned',
         sort: true
      },
      {
         dataField: 'userincentive',
         text: 'User Incentive',
         sort: true
      },
      {
         dataField: 'joined',
         text: 'Joined ?',
         sort: true
      },
      {
         dataField: 'comments',
         text: 'User Comments & Remarks',
         sort: true
      },
      {
         dataField: 'action',
         text: 'Action',
         sort: false,
         formatter: (cellContent, row) => {
            return (
               <div>
                  <button onClick={() => clientOffer(row)} className="btn btn-light">
                     <i className="mdi mdi-eye-outline text-primary"></i>Edit
                  </button>
                  <button onClick={() => deletOffer(row)} className="btn btn-light">
                     <i className="mdi mdi-delete-forever text-danger"></i>Delete
                  </button>

               </div>
            );
         }
      }
   ]

   const defaultSorted = [{
      dataField: 'id',
      order: 'desc'
   }];

   const rowEvents = {
      onClick: (e, row, rowIndex) => {
         // localStorage.removeItem('editrow');
         //localStorage.setItem('editrow', JSON.stringify(row));
      }
   };

   const clientOffer = async (row) => {
      //if(row.statuscode =='Inactive') {

      localStorage.removeItem('offerrow');
      localStorage.setItem('offerrow', JSON.stringify(row));
      setPageTitle("Edit Offer");
      setPageType('OFFER');
      setLgShow(true);

   }

   const deletOffer = async (row) => {


      Swal.fire({
         title: 'Are you sure you want to delete the offer ?',
         showConfirmButton: true,
         showCancelButton: true,
         confirmButtonText: "OK",
         cancelButtonText: "Cancel"
      }
      ).then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.value) {
            //suspendUser();
            continuedeleteoffer(row);

         }

      })


   }

   const showfilter = async () => {
      setPageTitle("Search");
      setPageType('DATEFILTER');
      setLgShow(true);

   }

   const continuedeleteoffer = async (row) => {



      const deleteResponse = await deleteofferInformation(row);

      toast(deleteResponse.message, {
         position: "top-right",
         autoClose: 2000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });
      loadinformation();
   }

   const hidemodal = async () => {
      setLgShow(false);
      loadinformation();

   }

   const searchFilter = async () => {

      const searchfields = JSON.parse(localStorage.getItem("fieldforms"));
      localStorage.setItem('filtersave', JSON.stringify(searchfields));
      searchfields.userid = sessionStorage.getItem('recruiterid');
      const recruiteroffers = await GetOffer(searchfields);
      const rowdata = [];
      let counter = 0;
      await recruiteroffers.data.reduce(async (promise, res) => {
         await promise;
         res.newcreateddate = moment(res.createddate).format('YYYY-MM-DD h:mm:ss a');
         rowdata.push(res);

         if (counter == recruiteroffers.data.length - 1) {
            rowdata.sort((x, y) => +new Date(y.jobcreatedate) - +new Date(x.jobcreatedate));
            setOffersdata(rowdata);
         }

         counter++;



      }, Promise.resolve());

      setLgShow(false);
   }
   return (
      <>
         <div className="col-12 grid-margin">

            <ToastContainer
               position="top-right"
               autoClose={2000}
               hideProgressBar={false}
               newestOnTop={false}
               closeOnClick
               rtl={false}
               pauseOnFocusLoss
               draggable
               pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
            <div className="card">
               <div className="card-body">
                  <h4 className="card-title">Candidate Offer List</h4>
                  <div className="text-right">

                     <Button className="btn-sm" onClick={showfilter} >Filter<i className="mdi mdi-filter ml-1"></i></Button>
                  </div>


                  <div className="row">
                     <div className="col-12">
                        <ToolkitProvider
                           keyField="id"
                           bootstrap4
                           data={offersdata}
                           columns={columns}
                           search
                        >
                           {
                              props => (
                                 <div>
                                    <div className="d-flex align-items-center">
                                       <p className="mb-2 mr-2">Search in table:</p>
                                       <SearchBar {...props.searchProps} />
                                    </div>
                                    <BootstrapTable
                                       defaultSorted={defaultSorted}
                                       pagination={paginationFactory()}
                                       {...props.baseProps}
                                       rowEvents={rowEvents}
                                       wrapperClasses="orders-table table-responsive"
                                    />
                                 </div>
                              )
                           }
                        </ToolkitProvider>
                     </div>
                  </div>



                  <div>
                     <Modal
                        size="lg"
                        show={lgShow}
                        onHide={hidemodal}
                        aria-labelledby="example-modal-sizes-title-lg"
                     >
                        <Modal.Header closeButton>
                           <Modal.Title>{pagetitle}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                           <EditOffer />
                           {
                              pagetype === 'VIEWJD' ? (
                                 <EditOffer />
                              ) : pagetype == 'DATEFILTER' ? (
                                 <DateFilter />
                              ) : null

                           }
                        </Modal.Body>

                        <Modal.Footer>
                           {
                              pagetype == 'DATEFILTER' ? (
                                 <>
                                    <Button variant="success m-2" onClick={searchFilter}>Search</Button>

                                    <Button variant="light mb-" onClick={hidemodal}>Close</Button>
                                 </>
                              ) : pagetype != 'DATEFILTER' ? (
                                 <Button variant="light mb-" onClick={hidemodal}>Close</Button>

                              ) : null
                           }
                        </Modal.Footer>
                     </Modal>


                  </div>
                  <div className="text-right">
                     <CSVLink
                        filename="candidate_offer_list"
                        data={offersdata}
                        headers={headers}>
                        Download
                     </CSVLink>

                  </div>
               </div>
            </div>
         </div>
      </>
   )

}

export default RecruiterOfferlist