
import React, { useEffect, useState } from 'react';
import '../profile/Profile.css';
import parse from "html-react-parser";
import { getContentInformation } from '../user-pages/User.service';
function UserContent(){
    
    const [filefields,setFileFields ] =useState("<h1></h1>"); 

    useEffect(() => {
        setUpInitialServices();
    
      }, []);

    const setUpInitialServices = async () => {
        const qerydata ={
          contenttype :localStorage.getItem('contentype')
        }
    
        const resp = await getContentInformation(qerydata);
        if(resp.data.length >0){
        setFileFields(resp.data[0].description);
        }
       
      } 

    return(
        <>
        <div className='text-center'>{parse(filefields)}</div>
       </>
    )

}

export default UserContent