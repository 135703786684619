import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { BankInfo, WorkInfomation } from '../profile/Profile.service';
import WebmailGuideline from '../profile/WebmailGuideline';

class SidebarRecruiter extends Component {

  constructor(props) {
    super(props);
   
    this.checkPersonalFilled = this.checkPersonalFilled.bind(this);
    this.opentab = this.opentab.bind(this);
    this.redirectmail = this.redirectmail.bind(this);
    this.hidemodal = this.hidemodal.bind(this);
  }

  opentab(){
   // window.open('https://login.bluehost.in/hosting/webmail');
   this.setState({ lgShow:true });
  }

  redirectmail(){
     this.setState({ lgShow:false });
      window.open('https://login.bluehost.in/hosting/webmail');
   
   }

  checkPersonalFilled(val) {
   // e.preventDefault();
 
    
   const setupdone =sessionStorage.getItem("setupdone");
   if (setupdone ==='Y') {
     if(val ===1) {
     //this.props.history.push('/ dashboard');
     }else if(val ===2){
      this.props.history.push('/recruiterjdlist');
     }
     else if(val ===3){
      this.props.history.push('/myworkspace');
     }
     else if(val ===5){
      this.props.history.push('/recruiteroffers');
     }
     else if(val ===4){
      this.props.history.push('/allcandidates');
     }
     else if(val ===8){
      this.props.history.push('/searchcandidate');
     }
    
     
   }else{
     toast('You are requested to fill up the Work Information before proceeding to the platform', {
       position: "bottom-right",
       autoClose: 2000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
   }
  }

  
  state = {
    username: '',
    recruiterid:'',
    category:'',
    rating:'3',
    lgShow:false
  };

  hidemodal(e){
    // e.preventDefault();
     this.setState({ lgShow:false });
   }
 

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      if(i !='username' &&  i!='recruiterid' && i !='category' && i!='rating' && i!='lgShow'){
      this.setState({ [i]: false });
      }
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="!#" className="nav-link" onClick={evt => evt.preventDefault()}>
             
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2"><Trans>{this.state.username}</Trans></span>
                <span className="text-secondary text-small"><Trans>Recruiter</Trans></span>
                <span className="font-weight-bold mb-2"><Trans>ID:{this.state.recruiterid}</Trans></span>
                <span className="font-weight-bold mb-2"><Trans>Category:{this.state.category}</Trans></span>
                <span className="font-weight-bold mb-2"><Trans>Rating:</Trans>
                 <span class="badge badge-pill badge-warning">{this.state.rating}</span> </span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
            
          </li>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title"><Trans>Dashboard</Trans></span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/profile') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/profile">
              <span className="menu-title"><Trans>My Profile</Trans></span>
              <i className="mdi mdi-contact-mail menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/recruiterjdlist') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" onClick={()=>this.checkPersonalFilled(2)} >
              <span className="menu-title"><Trans>List of JDs</Trans></span>
              <i className="mdi mdi-table-large menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/myworkspace') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" onClick={()=>this.checkPersonalFilled(3)}>
              <span className="menu-title"><Trans>My Workspace</Trans></span>
              <i className="mdi mdi-table-large menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/searchcandidates') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" onClick={()=>this.checkPersonalFilled(8)}>
              <span className="menu-title"><Trans>Candidate Database</Trans></span>
              <i className="mdi mdi-table-large menu-icon"></i>
            </Link>
          </li>
          
          <li className={this.isPathActive('/allcandidates') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" onClick={()=>this.checkPersonalFilled(4)}>
              <span className="menu-title"><Trans>My Candidates</Trans></span>
              <i className="mdi mdi-table-large menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/recruiteroffers') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" onClick={()=>this.checkPersonalFilled(5)}>
              <span className="menu-title"><Trans>Offers</Trans></span>
              <i className="mdi mdi-chart-bar menu-icon"></i>
            </Link>
          </li>
          
          <li className={this.isPathActive('/webmail') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" onClick={()=>this.opentab()}>
              <span className="menu-title"><Trans>Web Mail</Trans></span>
              <i className="mdi mdi-email-open menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" onClick={()=>this.checkPersonalFilled(6)}>
              <span className="menu-title"><Trans>Reports</Trans></span>
              <i className="mdi mdi-chart-bar menu-icon"></i>
            </Link>
          </li>

          <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />

        <Modal
                                size="lg"
                                show={this.state.lgShow}
                                onHide={this.hidemodal}
                                aria-labelledby="example-modal-sizes-title-lg"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>WebMail Guidelines</Modal.Title>
                                    <div className="text-right">
                                    <Button variant="success m-2" onClick={this.redirectmail} >Login Webmail</Button>
                                    </div>
                                </Modal.Header>

                                <Modal.Body>
                                 <WebmailGuideline/>
                                   
                                </Modal.Body>

                                <Modal.Footer>
                               
                                    <Button variant="light mb-" onClick={this.hidemodal}>Cancel</Button>
                                </Modal.Footer>
                            </Modal>

        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    this.setState({ username: sessionStorage.getItem('displayName') });
    this.setState({ recruiterid: sessionStorage.getItem('recruiterid') });
    this.setState({ category: sessionStorage.getItem('recruitercategory') });
    this.setState({ rating: sessionStorage.getItem('rating') });
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(SidebarRecruiter);