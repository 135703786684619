import React, { useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
/*export class AgreementContent extends React.PureComponent {
    render() {
      return (
        <div>My cool content here!</div>
      );
    }
  }*/

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const AgreementContent = React.forwardRef((props, ref) => {

  const fields = {
    recruitername: 'Talent Tracker',
    address: 'Pune',
    effectivedate: '____________________'
  }

  const [formfields, setFromFields] = useState(fields);
  const [isrecruiter, setIsrecruiter] = useState(false);

  useEffect(() => {
    setUpInitialServices();

  }, []);

  const setUpInitialServices = async () => {
    if (sessionStorage.getItem('userRole') === 'ROLE_RECRUITER') {
      const data = {
        recruitername: sessionStorage.getItem('displayName'),
        address: '_____________________',
        effectivedate: '____________________'

      }
      setFromFields(data);
      setIsrecruiter(true);
    } else {
      const rowdata = JSON.parse(localStorage.getItem('editrow'));
      const data = {
        recruitername: rowdata.firstname + ' ' + rowdata.lastname,
        address: ' Pune '

      }
      setFromFields(data);
      setIsrecruiter(false);
    }
  }

  const {
    recruitername, address, effectivedate

  } = formfields

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFromFields((prevState) => ({
      ...prevState,
      [name]: value,
    }))


  }

  return (
    <>
      { !isrecruiter && <div className="row">
        <div className="col-md-4">
          <Form.Group className="row">
            <label className="col-sm-3 col-form-label">Recruiter Name</label>
            <div className="col-sm-6">
              <Form.Control type="text" maxLength={50} name="recruitername"
                value={recruitername} onChange={handleChange} />
            </div>
          </Form.Group>

        </div>
        <div className="col-md-4">
          <Form.Group className="row">
            <label className="col-sm-3 col-form-label">Address</label>
            <div className="col-sm-6">
              <Form.Control type="text" maxLength={100} name="address"
                value={address} onChange={handleChange} />
            </div>
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group className="row">
            <label className="col-sm-3 col-form-label">Effective Date</label>
            <div className="col-sm-5">
              <Form.Control type="date" maxLength={100} name="effectivedate"
                value={effectivedate} onChange={handleChange} />
            </div>
          </Form.Group>
        </div>
      </div>
    }


      <div ref={ref}>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12 text-center" >
            <b> RECRUITER PARTNER AGREEMENT</b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12">
            This Agreement defines the terms of the agreement between the User &nbsp;
            <b>{recruitername} , {address} </b>and Search Advisers Services,
            <b>("Talent Trackers Consulting Private Limited")-</b> Address- G11,
            Karan Greens Society, Near Rozary International School,
            Mumbai-Bangalore highway, Warje, Pune 411058, India Effective from <b>{effectivedate}</b>."
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            The Duration of the term of this Agreement is 1 year.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            For The purpose of this agreement, both parties agree below the definition of Terms.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Definition of terms: </b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >

            <b>User(s), You, Your</b>-  Any individual or a group of Individuals, Recruiter, Manpower Consultancies, Agency, Search Firms, Independent Consultant, HR professional, Non-HR professional who is signing up to the platform of  Talent Trackers Consulting Pvt Ltd as a Recruitment Partner.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Client</b>- The Client company partnering with Talent Trackers Consulting Private Limited for availing the services of Manpower Recruitment. (Permanent or Contract Staffing)
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Talent Tracker (s), we, us -</b> Talent Trackers Consulting Private Limited
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Term:</b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            This Agreement is effective as of the execution date and terminates One year from that date.  Either party may terminate this agreement at any time upon written notice of 7 Days to the other party. If, after one year, both the parties wish to continue assigning/submitting freelance projects, a new agreement shall be signed; but if the parties fail to do so by error or oversight, the terms of this Agreement shall be presumed to be binding until or unless a newer agreement is signed.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Quick Guide to Talent Tracker’s Recruitment Platform:</b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            •	User to Sign up https://www.talenttrackerhr.in

          </div>
        </div>
        <div class="row">
          <div className="col-md-12" >
            •	User to View, Filter, Lock JDs in “List of JDs” area.
          </div>
        </div>
        <div class="row">
          <div className="col-md-12" >
            •	User to Add candidates to tracker sheet, Submit CVs to clients from the automated software of platform.
          </div>
        </div>
        <div class="row">
          <div className="col-md-12" >
            •	User to View, Filter, Download & assess User performance, Ratings, Reports and Dashboard.
          </div>
        </div>


        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Acceptance of Work:</b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>

        <div class="row">
          <div className="col-md-12" >
            User’s Primary Duties &Responsibilities include:
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            •	End to end CVs sourcing & CVs delivery submission to clients as per the desired timeline, quality and service

          </div>
        </div>
        <div class="row">
          <div className="col-md-12" >
            • End to end Candidate Coordination
          </div>
        </div>
        <div class="row">
          <div className="col-md-12" >
            • Client coordination (case to case basis)
          </div>
        </div>
        <div class="row">
          <div className="col-md-12" >
            • Understanding the rules of the portal and follow the Standard Operating Process.
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Work Assignment:</b> Each Work assignment created or Developed or done by User and submitted to Talent Trackers must be acceptable to Talent Trackers in terms of quality, length, style, technical aspects, timeliness, and subject matter, and be created in accordance with typical professional and ethical standards appropriate to the type of Work it is.  User must also be free from any actual or perceived conflicts of interests. Once a Work is accepted, Talent Trackers shall have the right to edit the Work and determine whether or how to represent that to client of Talent Trackers, or otherwise disseminate it. User will provide Talent Trackers with background information it needs, including fact- checking materials, data, technical information, information about assignments or any other materials or information that Talent Trackers reasonably requests.  User warrants that the information she/he provides is accurate and complete.
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Sign up rates:</b> Sign up rates agreed by Talent Trackers with Clients are percentage on “Gross annual CTC” of the candidate (Fix pay, without variables). The standard rates are 8.33% (1 month salary of the candidate), however the sign up rates can also be 7%,7.5%,7.25%, 10%,12%, 16%, 6%  based on the negotiations happen with Clients. Talent Trackers discloses the sign up rates to User while sharing JD with User. The minimum Sign up rate- is 6% below which Talent Trackers does not accept any work from Clients. Approximately 70% Clients have 8.33% sign up rates
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Replacement: </b>The standard replacement terms agreed with Clients are 3 months. However, they can vary up to 6 months on case-to-case basis. In case the candidate quits job within the agreed replacement period, It is mandatory on User’s part to prioritize the replacement sourcing. (User needs to source the next batch of profiles within 7 days). Majority of Talent Trackers Clients fall into 3 months replacement term. Talent Trackers discloses the Replacement period terms to User while sharing JD with User
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Payment Terms:</b> The standard Payment terms agreed between Talent Trackers and their Clients are 30 days from the date of joining of the candidate. However, with few clients, the payment terms can vary up to 60 days on case-to-case basis. The pay-out compensation to the User is paid only after the payment receipt from the Clients- on the same day of payment Credit to Talent Trackers. The pay outs are issued to user in the bank details provided by user at the time of Sign up. The payment transfer is electronic (NEFT/IMPS) without TDS deduction if the User is an individual, however TDS is deducted if User is registered with Talent Trackers as a consultancy. The Consultancy is defined any legal entity which contains a Proprietorship license, Partnership agreement, LLP or Private Limited Company. It is user’s prerogative to decide if User wishes to register with Talent Trackers as an individual or a Consultancy. Both have their own rights and privileges in the set up of Talent Trackers.        </div>
        </div>


        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Delay in payments:</b> Talent Trackers have a very strict policy with Clients for the timely release of payments. However, there can be unavoidable delays from Client’s end- & it is a part of Recruitment business! In case of delays, Talent Trackers communicates to User in advance with possible expected date. Generally, delays are not more than 1 or 2 weeks. There could be early payments too before the expected date! Majority of our Clients clear the invoices on time.
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Delay in payments:</b> Talent Trackers have a very strict policy with Clients for the timely release of payments. However, there can be unavoidable delays from Client’s end- & it is a part of Recruitment business! In case of delays, Talent Trackers communicates to User in advance with possible expected date. Generally, delays are not more than 1 or 2 weeks. There could be early payments too before the expected date! Majority of our Clients clear the invoices on time.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            It is not mandatory for user to handle the Client Coordination. You can limit the scope of your work to the Candidate Coordination.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Pay-out :</b> The pay outs are fixed for a JD & mentioned in the “Terms of JD” section in “List of JDs”. There are two ways Talent Trackers offers the pay- out to users.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> 1.	The Percentage (%) of Base Invoice Value:</b>  It is a product of Base invoice value without GST and the percentage ( % ) pay out offered to User in the particular JD.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >

            <Table bordered>
              <thead>
                <tr>
                  <th><b>Percentage (%) Pay out:</b></th>
                  <th><b>Example:</b></th>
                </tr>

              </thead>
              <tbody>
                <tr>
                  <td>User’s pay out = Base Invoice value without GST * percentage (%) pay-out </td>
                  <td>If 40% is the offered pay-out to User as mentioned in the JD.<br></br>
                    If say, CTC of the selected candidate- 10 lakh<br></br>
                    If say, Talent Tracker’s sign up rate with client – 8.33%<br></br>

                    <b>Then,</b><br></br>

                    Base Invoice value without GST: 10 lakh * 8.33% = INR 83,300/-<br></br>
                    <b>User’s pay out 40% 0f INR 83,300/– i.e INR 33,320/-</b>
                  </td>
                </tr>

              </tbody>
            </Table>
          </div>
        </div>


        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            2.<b>Absolute pay out: </b>Talent Trackers may offer User an “Absolute pay out” for the JD. Such pay-out can slightly vary based on the actual CTC offered to the candidate.    </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >

            <Table bordered>
              <thead>
                <tr>
                  <th><b>Absloute Value Pay out:</b></th>
                  <th><b>Example:</b></th>
                </tr>

              </thead>
              <tbody>

                <tr>
                  <td></td>
                  <td>If say, CTC budget for a JD: 15-20 lakh <br></br>
                    If say, Pay out range offered to User by Talent Trackers: 40-50k
                    for a successful closure.<br></br>

                    <b>Then,</b> <br></br>

                    Based on actual CTC offered to the candidate by Client,<br></br>
                    pay-out can be anything between 40-50k (Pay out will be 40k for 15 Lacs <br></br>
                    offered CTC , & 50k for 20 lacs CTC)<br></br>

                  </td>
                </tr>

              </tbody>
            </Table>
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Factors considered for the Pay outs:</b> Pay outs change from JD to JD and are generally in the range of 30%-70% of billing invoice value. Pay outs are equal for all users without any discrimination. They generally depends on few factors.  1. CTC budget of the JD. 2. Niche/Difficulty level of JD. 3. Sing up rate with client  4. Exclusivity we have with client. 5. Long term strategic relation with the client. 6. Aging of JD (Fresh or Rework). It is the Talent Tracker’s team, which decides the pay out & their decision is final. Pay outs are unchanged once offered. You get an email confirmation from Talent trackers about pay out offered once you lock the JD.
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> JD status change: </b> You can change the JD status as you progress end-to-end recruitment for a particular JD. The JD status can be amended online from your account in “My Workspace- “Edit Job Status”. JD status helps admin to understand the progress of your mandate
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            We have following JD status on our platform:
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            1. <b>Open </b>– JD is yet not accepted by any Recruiter and you can lock it.<br></br>
            2. <b>Pending delivery </b>- JD is locked by user but CVs submission is yet not done.<br></br>
            3. <b>Delivery done</b>-await client feedback- CVs submission is done, however waiting for client’s feedback on candidate shortlisting.<br></br>
            4. <b>Interview stage</b>-  Rounds of interviews have started (Telephonic, virtual, Face to Face, HR/Management round etc.)<br></br>
            5. <b>Offer stage</b>- Candidate is selected, offer in discussion.<br></br>
            6. <b>Offer accepted</b>- Serving Notice Period<br></br>
            7. <b>Joined</b><br></br>
            8. <b>Invoice raised, await payment</b><br></br>
            9. <b>Rework (with reasons)</b><br></br>

          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            You can view your JD status as well as other Recruiter’s JD status from your category. You need to timely discuss the JD status with admin to address any gaps in understanding.
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Candidate Coordination:</b> It is important that you do end-to-end coordinate with the Candidates on time. Also respond to the client Emails from Talent Trackers official Email ID issued to you and take actions at the sourcing, interview/offer stage of the candidate. It is to note, any given day, Talent Trackers deal with hundreds of open JDs and multiple Recruitment partners work on the same. Hence, it is important for you to handle your candidate coordination well & align with the admins. Admins have limited bandwidth to  check/follow up with you for you candidate coordination.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> No Submissions/Delayed Submissions:</b> It is imperative for User to make the submissions within the agreed Turn-around time (24 hours from the JD acceptance date-time). In case User fails to do the submission within the agreed turn-around time, the JD can be released for other Users to lock, affecting the current User’s rating. Talent Trackers has 24 hours Turn around time commitment for submissions with clients.   </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Exclusivity: </b>Majority of the JDs in our system are exclusive to you once assigned (Exclusivity means you are the only Recruiter working on the JD once assigned).  Once you lock a particular JD, it moves to “my workspace” of your account and other Recruiters can not able to lock it. Exclusivity decision is Talent Tracker’s prerogative & depends on following factors. 1. Your responsiveness and speed. 2. Quality of CVs submission. 3. No. of closures you do per month. 4. Client’s feedback about your performance 5. Admin’s rating. However, there is no obligation on Talent Trackers to make any JD exclusive for any users.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Duplication of CVs:</b> It can happen that there are duplication of submitted CVs found in the Client’s screening. Such cases are prevalent if Client has empaneled multiple Consultancies or Client’s internal team working on a given mandate. The duplication status is communicated to us by Clients.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Process Adherence </b>- This agreement confirms that User to honor the Standard Operating Process (SOP) and the rules/conditions of the portal usage for effective results. The Automated platform https://www.talenttrackerhr.in is a proprietary in-house IP (Intellectual Property) of Talent Trackers & Talent Trackers reserves all rights to define , set or change the architecture, design, rules, processes and proprietary of the platform. There are user guidelines and video blogs available at every page on the platform helping you to understand the processes.
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Payment Adjustments:</b>  In a rare scenario, it can happen that User’s pay-out is already paid by Talent Trackers for a successful closure. Later, the candidate quits the job within the agreed replacement period. Talent Trackers, offers replacement to Client, however, the Client either refuses to take the replacement from Talent Trackers or the User fails to give the replacement in the speculated timeline. In either cases, Client might request Talent Trackers for a refund of the already processed payment or request to adjust the payment against the next ongoing JD. In either cases, Talent Trackers proposes following adjustment methods to Users for payment recovery.
            User to handle the next ongoing JD from the same Client & achieve closure. Or User to handle any other JD from any other Client and Talent Trackers can adjust the pay out against the successful closure. Talent Trackers offers 30 days window to User for a next successful closure. If User fails to achieve the closure within 30 days attracts the refund of payment from User to Talent Trackers.

          </div>
        </div>


        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            To note, these are very rare scenario and not prevalent happening.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> No response:</b> There are JDs might having no/slow response from Client for the CVs submitted. Talent Trackers does the best efforts to validate the genuinely of client’s requirement in advance & escalates at Client’s Top Management for the speedy and timely feedback, however, No/Slow feedbacks are the part-and-parcel of Recruitment business and beyond a point, not in control of us. User should be well aware of these Industry Compulsions.
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>CVs validity:</b> Candidate sourced by User for a particular Client will be valid for 12 months from the date of submission of CV.    </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Account Suspension: </b>Talent Trackers reserves rights to downgrade User’s
            rating or suspend User’s account any point in time in case of following circumstances:<br></br>

            No activity.<br></br>
            Integrity/proprietary issues.<br></br>
            Poor quality of CVs<br></br>
            Non-compliance to the policy & terms of the portal.<br></br>
            Non-performance: Failure to do the delivery submissions or end-to-end coordination.<br></br>

          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Candidate Database:</b> We recommend Users to use the professional Job portals for faster & improved results. We prefer onboarding Recruiters already having an access to the job portals. Talent Trackers database is a back-up and may not suffice for precise search for a given JD.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Talent Tracker’s Candidate Database:</b>  It is a proprietary in-house database provided to Users free of cost as a stop-gap arrangement before you sign up the professional Job Portals. Our database is accurate- however, may have limitations of aging, coverage and range. You can online access our database once sign up to the platform. The candidate database is created with last 10 years sourcing efforts by Talent Trackers team or its affiliated partners, vendors, recruiters who contributed, shared, assisted the data building process. It can also through a direct subscription of candidates to our portal submitting their candidature. The User agrees not to copy, disseminate or use this Proprietary Information in anyway, unless the purpose of Subject JD work, and not beyond the agreed usage limit. User agrees not to directly or indirectly copy, scrap, parse, extract, sell, resell, franchise, reproduce, rebrand, affiliate, subscribe disseminate this candidate database using any automated tool, script, software, bot or any manual intervention.  Talent Tracker treats any incidence found as an impeachment or breach of integrity in dealing with Talent Tracker’s Candidate Database with zero tolerance. Talent Trackers reserves rights to take an appropriate action in such cases, which includes cancellation of the User agreement contract or unsubscribing the User from using the platform.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>User’s warranties: </b> User also represents and warrants that neither the creation nor ordinary use of the Work violates the rights of others (including any intellectual property, privacy, or publicity rights) or any criminal law, and that User has the knowledge and authority to enter into this Agreement.  If the Work includes any material that is not original to User, it is the obligation of User to notify Talent Trackers; to obtain any necessary clearances, rights, licenses or releases; and to provide documentation thereof to Talent Trackers

          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Mutual indemnification:  </b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b> Talent Trackers</b> shall indemnify User against claims, damages, liabilities, costs and expenses (including reasonable attorney’s fees) that result from any unauthorized use of the Work by Talent Trackers.  User shall indemnify NPR and its members, sub licensees, or assignees against claims, damages, liabilities, costs and expenses (including reasonable attorney’s fees) that result from any breach of User’s representations, warranties or obligations.  If a claim is made against Talent Trackers arising out of the use of the work, User agrees to cooperate fully, as needed, to defend against it.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Independent Contractor: </b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            It is expressly acknowledged by the parties that User is an independent contractor and not an employee of Talent Trackers. Nothing in this agreement or the parties’ conduct should be construed as creating an employer-employee relationship, joint venture, partnership or any other kind of arrangement other than that of an independent contractor.  User shall have total control over the manner, means, and methods of his/her work, shall use his/her own equipment, and shall set his/her own schedule.  If User seeks the assistance Talent Trackers is committed to provide training support from their In-house Team in accordance to finish the assignment. Talent Trackers shall not provide User with office space, tools, equipment, (with the exception that Talent Trackers may provide information, training or special equipment to the extent necessary for User to comply with Talent Trackers’ unique policies, procedures or systems).Talent Trackers shall not withhold any sums for income tax, social security, unemployment insurance, disability insurance, workers compensation insurance or any other withholding applicable to employees, nor shall User be eligible for any employee benefits Except for the TDS. User is solely responsible for all taxes, insurance or other costs and agrees to hold Talent Trackers harmless from any liability with respect thereto. User may not represent him/herself as an employee of Talent Trackers.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Entire agreement:   </b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            This Agreement overrides any previous agreements and constitutes the entire agreement between the parties.  No modifications or waivers can be made to this Agreement unless in a writing signed by both parties.  A modification or waiver of a part of this Agreement shall not constitute a waiver or modification of any other part.  User may not assign any part of this Agreement.  Nothing in this Agreement creates any enforceable rights in any person that is not a party to the Agreement.
          </div>
        </div>

        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Choice of law and forum:    </b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            This Agreement is governed by and construed in accordance with the laws of the Jurisdiction of Pune, Maharashtra, without regard to its choice of law rules.  All disputes arising from this Agreement must be brought in courts within the Jurisdiction of Pune.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Survival:     </b>
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            Any provisions which by their terms survive the expiration or termination of this Agreement shall bind the parties, and their legal representatives, successors and permitted assigns, as set forth herein.
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            Annexure:  <img src={require('../../assets/images/signature.jpg')} alt="logo" />
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-6" ></div>
          <div className="col-md-6" >
            Amol Kale
          </div>
        </div>
        <div class="row"><div className="col-md-12">&nbsp;</div></div>
        <div class="row">
          <div className="col-md-12" >
            <b>Agreed:     </b>
          </div>
        </div>

        <div class="row">
          <div className="col-md-12" >

            <Table >
              <thead>

              </thead>
              <tbody>
                <tr>
                  <td >
                    <line> --------------------</line><br></br>
                    user <br></br><br></br>
                    <line>-------------------- </line><br></br><br></br>
                    Name <br></br><br></br>
                    <line>--------------------</line><br></br><br></br>
                    Date <br></br><br></br>
                  </td>
                  <td ></td><td ></td><td ></td><td ></td><td ></td>
                  <td ></td><td ></td>
                  <td>
                    Search Advisers  Service <br></br>
                    (Talent Trackers Consulting Private Limited Pune)<br></br><br></br>
                    <line>--------------------</line><br></br><br></br>
                    <line>--------------------</line><br></br><br></br>
                    Date <br></br><br></br>
                  </td>
                </tr>

              </tbody>
            </Table>
          </div>
        </div>


      </div>
    </>
  );
});