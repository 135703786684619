
import React, { useState } from 'react';
import {Button, Form } from 'react-bootstrap';
import './Unsubscribe.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { UpdateUserStatus } from '../profile/Profile.service';
function Unsubscribe() {

    const [checkedOne, setCheckedOne] = useState(false);
    const updateOne = () => setCheckedOne((prev) => !prev);

    const [checkedTwo, setCheckedTwo] = useState(false);
    const updateTwo = () => setCheckedTwo((prev) => !prev);

    const [checkedThree, setCheckedThree] = useState(false);
    const updateThree = () => setCheckedThree((prev) => !prev);
    const [checkedFour, setCheckedFour] = useState(false);
    const updateFour = () => setCheckedFour((prev) => !prev);

    const [checkedFive, setCheckedFive] = useState(false);
    const updateFive = () => setCheckedFive((prev) => !prev);

    const [checkedSix, setCheckedSix] = useState(false);
    const updateSix = () => setCheckedSix((prev) => !prev);
    const [feedback, setFeedback] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFeedback(value);
        // console.log(formfields);
    }

    const saveChanges =async() =>{

        if(!checkedOne  && !checkedTwo && !checkedThree && !checkedFour && !checkedFive 
            && !checkedSix){

                toast('Please select atleast one reason', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            

            }else{

        Swal.fire({
            title: 'Are you sure you want to unsubscribe ?',
            text:'You will not be able to access the application again',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel"
            }
          ).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.value) {
              okcontinue();
      
            } 
      
          })   
        }

    }

    const okcontinue = async() =>{
        let reason ='';
        if(checkedOne){
            if(reason ==''){
                reason ='Found a better product';
            }else{
                reason =reason +', Found a better product';
            }
        }
        if(checkedTwo){
            if(reason ==''){
                reason =' Not using any more';
            }else{
                reason =reason +', Not using any more';
            }
        }
        if(checkedThree){
            if(reason ==''){
                reason ='Cant find companies or contacts';
            }else{
                reason =reason +', Cant find companies or contacts';
            }
        }
        if(checkedFour){
            if(reason ==''){
                reason =' It is too expensive';
            }else{
                reason =reason +', It is too expensive';
            }
        }
        if(checkedFive){
            if(reason ==''){
                reason ='Data quality issue';
            }else{
                reason =reason +', Data quality issue';
            }
        }
        if(checkedSix){
            if(reason ==''){
                reason ='Found bugs in product';
            }else{
                reason =reason +', Found bugs in product';
            }
        }
       

        const savedata = {
            id: sessionStorage.getItem('userId'),
            status: 'Unsubscribe',
            changedby: sessionStorage.getItem('userId'),
            reason:reason,
            feedback:feedback
        }
      
        const retResponse = await UpdateUserStatus(savedata);
      
        toast(retResponse.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        window.location ='/';
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" className="form-check-input"
                                    checked={checkedOne} onChange={updateOne} />
                                Found a better product
                            
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={checkedTwo} onChange={updateTwo}
                                    className="form-check-input" />
                               Not using any more
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={checkedThree} onChange={updateThree}
                                    className="form-check-input" />
                              Cant find companies or contacts
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={checkedFour} onChange={updateFour}
                                    className="form-check-input" />
                               It is too expensive

                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={checkedFive} onChange={updateFive}
                                    className="form-check-input" />
                              Data quality issue

                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={checkedSix} onChange={updateSix}
                                    className="form-check-input" />
                              Found bugs in product

                            </label>
                        </div>

                        <div className="col-md-12">
                                <Form.Group className="row">
                                    <label className="col-sm-3 col-form-label">Feedback(Optional)</label>
                                    <div className="col-sm-9">
                                        <Form.Control as="textarea" 
                                        rows="3" col="12"  />
                                    </div>
                                </Form.Group>

                            </div>
                      
                    </Form.Group>
                    <Button variant="success m-2" onClick={saveChanges}>Unsubscribe</Button>
                    
                </div>

            </div>

        </>
    )

}

export default Unsubscribe