
import React from 'react';
function WebmailGuideline() {

    return (
        <>
            <div>
                <div class="row">
                    <div className="col-md-12">
                        <b> How to login your official Email ID of Talent Trackers through Webmail? </b>
                    </div>
                </div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row">
                    <div className="col-md-12">
                        <b>Step1-</b> Connect the webmail login page with below link-  https://talenttrackerhr.com/webmail/

                    </div>
                </div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row">
                    <div className="col-md-12">
                        <b> Step2-</b>  enter your official email ID as given in My profile  Admin assigned information
                    </div>
                </div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row">
                    <div className="col-md-12" >
                        <img src={require('../../assets/images/webmail.png')} alt="logo" />
                    </div>
                </div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row">
                    <div className="col-md-12">
                    <b>Step3-</b> Enter the password. The system generated password is Welcome@54321
                     for the first login, & later you can change if you wish. 
                     (change password- webmail home --- edit your settings ---password and security)
                    </div>
                </div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row">
                    <div className="col-md-12">
                    How to configure the mailbox in Outlook/Android phone
                    </div>
                </div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row">
                    <div className="col-md-12">
                    If you are familiar with Outlook/Android phone to access your mailbox, you can use below settings , else you can continue to use Webmail for email exchanges.
                   Please visit below link for step-by-step guide for outlook settings of bluehost webmail

                    </div>
                </div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row"><div className="col-md-12">&nbsp;</div></div>
                <div class="row">
                    <div className="col-md-12">
                    If you are familiar with Outlook/Android phone to access your mailbox, you can use below settings , else you can continue to use Webmail for email exchanges.
                   Please visit below link for step-by-step guide for outlook settings of bluehost webmail <br></br><br></br>
                   <u>https://www.bluehost.com/help/article/email-application-setup-outlook </u> <br></br><br></br>

                   Please visit below link for step-by-step guide for Android phone settings of bluehost webmail <br></br><br></br>
                  <u> https://www.bluehost.com/help/article/email-application-setup-android-devices </u>
                    </div>
                </div>



            </div>

        </>

    )

}

export default WebmailGuideline