import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../profile/Profile.css';
import { ChangepasswordbyAdmin } from '../profile/Profile.service';
import { Button, Form } from 'react-bootstrap';


function PasswordUpdate() {

    const fields = {
        newpassword: '',
        confirmpassword: ''
    }
    const errors = {
        newpassword: '',
        confirmpassword: ''
    }

    useEffect(() => {
        // setUpInitialServices();

    }, []);

    const [formfields, setFromFields] = useState(fields);
    const [errorFields, setErrorFields] = useState(errors)

    const {
        newpassword, confirmpassword
    } = formfields

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFromFields((prevState) => ({
            ...prevState,
            [name]: value,
        }))
        // console.log(formfields);
    }

    const validateForm = async () => {
        let fields = formfields;
        let errors = {};
        let formIsValid = true;

        if (!fields["newpassword"]) {
            formIsValid = false;
            errors["newpassword"] = "*Please enter your password.";
        }

       /* if (typeof fields["newpassword"] !== "undefined") {
            if (!fields["newpassword"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
                formIsValid = false;
                errors["newpassword"] = "*Please enter secure and strong password.";
            }
        }*/

        if (typeof fields["newpassword"] !== "undefined") {
            if(fields["newpassword"].length < 8){
             formIsValid = false;
             errors["newpassword"] = "*Password should be min of 8 characters";
            }
         }

        if (fields["newpassword"] != fields["confirmpassword"]) {
            formIsValid = false;
            errors["confirmpassword"] = "*New password and confirm password should be same.";
        }

        setErrorFields(errors)
        return formIsValid;

    }

    const changePassword = async () => {

        let isvalid = await validateForm();

        if (isvalid) {
            const savedata = {
                id: sessionStorage.getItem('userId'),
                password: formfields.newpassword,
                changedby: sessionStorage.getItem('userId')
            }

            const retResponse = await ChangepasswordbyAdmin(savedata);
            if (retResponse.data == 'Success') {
                toast(retResponse.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                window.location = "/";
            }

        }


    }


    return (
        <>
            <div className="col-12 grid-margin">
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample">
                            <Form.Group className="row">
                                <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Password</label>
                                <div className="col-sm-9">
                                    <Form.Control type="text" className="form-control"
                                        id="exampleInputPassword2" placeholder="Password"
                                        name="newpassword" value={newpassword} onChange={handleChange} />
                                </div>
                            </Form.Group>
                            <Form.Group className="row">
                                <label htmlFor="exampleInputConfirmPassword2" className="col-sm-3 col-form-label">Re Password</label>
                                <div className="col-sm-9">
                                    <Form.Control type="text" className="form-control"
                                        id="exampleInputConfirmPassword2" placeholder="Password"
                                        name="confirmpassword" value={confirmpassword} onChange={handleChange} />
                                </div>
                            </Form.Group>
                            <Button variant="success m-2" onClick={() => changePassword()}>Change</Button>
                        </form>
                    </div>
                </div>
            </div>

        </>

    )

}

export default PasswordUpdate
