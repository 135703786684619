
import React, { useEffect, useState } from 'react';
import { Form,Button } from 'react-bootstrap';
import '../profile/Profile.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetOffer, SaveOffer, UpdateOffer } from '../jd/Job.service';
import { GetRecruiterJobs, personalInfo } from '../profile/Profile.service';
function EditOffer(){

   const fields ={
    jobcode:'',
    userid:'',
    candidateid:'',
    clientid:'',
    candidatename: '',
    designation:'',
    worklocation:'',
    offeredctc:'',
    signuprate: '',
    baseinvoiceval:'',
    offeraccepted:'',
    dateofjoining:'',
    resigned: '',
    copyofresignationrecieved:'',
    userincentive:'',
    joined:'',
    comments: '',
    recruitername:'',
    clientname:'',
    jdtitle:'',
    candidatemobileno: '',
    recruiterpaypercentage:'',
    recruiterpayabsloute:'',
    totalpayoutvalue:'',
    talenttrackershare:'',
    expectedpaymentrecievemonth:'',
    paymentrecieved:''
   } 

   const errors ={
    jobcode:'',
    userid:'',
    candidateid:'',
    clientid:'',
    candidatename: '',
    designation:'',
    worklocation:'',
    offeredctc:'',
    signuprate: '',
    baseinvoiceval:'',
    offeraccepted:'',
    dateofjoining:'',
    resigned: '',
    copyofresignationrecieved:'',
    userincentive:'',
    joined:'',
    comments: '',
    recruitername:'',
    clientname:'',
    jdtitle:'',
    candidatemobileno: '',
    recruiterpaypercentage:'',
    recruiterpayabsloute:'',
    totalpayoutvalue:'',
    talenttrackershare:'',
    expectedpaymentrecievemonth:'',
    paymentrecieved:''
   } 

   const [formfields, setFromFields] = useState(fields);
   const [errorFields, setErrorFields] = useState(errors);
   const [baseinvoice, setBaseInvoice] = useState(null);
   const [recruiterpayout,setRecruiterpayout] =useState(null);
   const[talenttrackerpayout,setTalenttrackerpayout] =useState(null);
   const[recruiterjobInfo, setRecruiterJobInfo] = useState(null);
   const[paytype,setPaytype]=useState(null);
   const[isvisible,setIsvisible] =useState(false);

   const {
    jobcode,userid,candidateid,clientid,candidatename,designation,worklocation,offeredctc,
    signuprate,baseinvoiceval,offeraccepted,dateofjoining,resigned,copyofresignationrecieved,
    userincentive,joined,comments,recruitername,clientname,jdtitle,candidatemobileno,
    recruiterpaypercentage, recruiterpayabsloute,totalpayoutvalue,talenttrackershare,
    expectedpaymentrecievemonth,paymentrecieved
   }  =formfields
   
   useEffect(() => {
    setUpInitialServices();

}, []);

const setUpInitialServices = async () => {
       
    const storedofferdetails = JSON.parse(localStorage.getItem('offerrow'));
    const setdata ={
    jobcode : storedofferdetails.jobcode,
    clientid :storedofferdetails.clientid,
    clientname : storedofferdetails.clientname,
    jdtitle : storedofferdetails.jdtitle,
    recruitername :storedofferdetails.recruitername,
    userid :storedofferdetails.userid,
    signuprate :storedofferdetails.signuprate
    }

    
    setFromFields(setdata);

     const reqquery ={
        jobcode:storedofferdetails.jobcode
     }
     const offerdetails = await GetOffer(reqquery);  
      if(offerdetails.data.length >0){
        setFromFields(offerdetails.data[0]);
        setBaseInvoice(offerdetails.data[0].baseinvoiceval);
        setRecruiterpayout(offerdetails.data[0].userincentive);
     }   
          
    
   

    if(sessionStorage.getItem('userRole') =='ROLE_RECRUITER'){
        setIsvisible(false);
        const reqdata ={
            jobcode:storedofferdetails.jobcode,
            userid:sessionStorage.getItem('userId'),
            fromdate: '',
            todate: '',
            status: ''
        }
        const recruiterjobinfo = await GetRecruiterJobs(reqdata);
        setRecruiterJobInfo(recruiterjobinfo.data[0]);
    }else{

        const fields ={
            recruiterid:storedofferdetails.userid
        }
         let personalinfo = await personalInfo(fields);  
    
        setIsvisible(true);
        const reqdata ={
            jobcode:storedofferdetails.jobcode,
            userid:personalinfo.data.id,
            fromdate: '',
            todate: '',
            status: ''
        }
        const recruiterjobinfo = await GetRecruiterJobs(reqdata);
        setRecruiterJobInfo(recruiterjobinfo.data[0]);
    }



}

const handleChange = (e) => {
    const { name, value } = e.target;
    setFromFields((prevState) => ({
        ...prevState,
        [name]: value,
    }))
    // console.log(formfields);
    console.log(value);
    if(name ==='offeredctc'){
        console.log(formfields.signuprate);
        
        const basinvoice = Math.round(parseFloat(value) * parseFloat(formfields.signuprate) /100);
        setBaseInvoice(basinvoice);
        console.log(basinvoice);

        //const jddetails = JSON.parse(localStorage.getItem('editrow'));
        const rcpayout =   recruiterjobInfo.payout;
        if(recruiterjobInfo.payouttype){
        setPaytype(recruiterjobInfo.payouttype);
        }else{
            setPaytype('Percentage');  

        }
        const rcpay =Math.round(parseFloat(basinvoice) * parseFloat(rcpayout) /100);
        setRecruiterpayout(rcpay);
        const ttpay =  Math.round(parseFloat(basinvoice) -parseFloat(rcpay));
        //alert(ttpay);
        setTalenttrackerpayout(ttpay);

        //const paytpe= jddetails.recruiterjobinfo.payouttype;
       // setPaytype(paytpe);
    }
}

const validateForm = async () => {
   // const jddetails = JSON.parse(localStorage.getItem('editrow'));
    const rcpayout =   recruiterjobInfo.payout;
    formfields.baseinvoiceval =baseinvoice;
    formfields.talenttrackershare =talenttrackerpayout;
    formfields.userincentive =recruiterpayout;
   
    if(paytype !=='Percentage'){
        formfields.recruiterpayabsloute = rcpayout; 
        formfields.recruiterpaypercentage = ''; 
        
    }else{
        formfields.recruiterpaypercentage = rcpayout; 
        formfields.recruiterpayabsloute = ''; 
    }
    let fields = formfields;
    let errors = {};
    let formIsValid = true;

    

    if (!fields["jobcode"]) {
        formIsValid = false;
        errors["jobcode"] = "*Please enter job code.";
    }

    if (!fields["userid"]) {
        formIsValid = false;
        errors["userid"] = "*Please enter recruiter id .";
    }
    if (!fields["candidateid"]) {
        formIsValid = false;
        errors["candidateid"] = "*Please enter candidate id .";
    }
    if (!fields["candidatemobileno"]) {
        formIsValid = false;
        errors["candidatemobileno"] = "*Please enter candidate mobile number.";
    }

    if (!fields["candidatename"]) {
        formIsValid = false;
        errors["candidatename"] = "*Please enter candidate name.";
    }
    if (!fields["designation"]) {
        formIsValid = false;
        errors["designation"] = "*Please enter designation .";
    }
    if (!fields["worklocation"]) {
        formIsValid = false;
        errors["worklocation"] = "*Please enter work location .";
    }
    if (!fields["offeredctc"]) {
        formIsValid = false;
        errors["offeredctc"] = "*Please enter offer ctc .";
    }
    if (!fields["signuprate"]) {
        formIsValid = false;
        errors["signuprate"] = "*Please enter signup rate .";
    }
    if (!fields["baseinvoiceval"]) {
        formIsValid = false;
        errors["baseinvoiceval"] = "*Please enter base value .";
    }
    if (!fields["offeraccepted"]) {
        formIsValid = false;
        errors["offeraccepted"] = "*Please select offer accepted .";
    }
    if (!fields["dateofjoining"]) {
        formIsValid = false;
        errors["dateofjoining"] = "*Please enter date of joining .";
    }
    if (!fields["resigned"]) {
        formIsValid = false;
        errors["resigned"] = "*Please select resigned .";
    }

    if (!fields["copyofresignationrecieved"]) {
        formIsValid = false;
        errors["copyofresignationrecieved"] = "*Please select copy of resignation recieved .";
    }

    if (!fields["userincentive"]) {
        formIsValid = false;
        errors["userincentive"] = "*Please enter user incentive .";
    }
    if (!fields["joined"]) {
        formIsValid = false;
        errors["joined"] = "*Please select joined .";
    }
    if (!fields["comments"]) {
        formIsValid = false;
        errors["comments"] = "*Please enter comments .";
    }
   
    setErrorFields(errors)
    return formIsValid;

}


const saveOffers = async() =>{
    const isvalid  = await validateForm();
   // console.log(isvalid);
    //console.log(formfields);
    if(isvalid){
        const jddetails = JSON.parse(localStorage.getItem('editrow'));
       
        formfields.officialemailid = jddetails.officialemailid;
 
       if(!formfields.id) {
         const response  =await SaveOffer(formfields);

         toast(response.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }else{
        const response  =await UpdateOffer(formfields);

        toast(response.message, {
           position: "top-right",
           autoClose: 2000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
       });
    }
      

    }


}
const clearform =async() =>{
  setFromFields(fields);
}


  return(
   <>
   <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
   <div className="card">
                <div className="card-body">
                    <form className="form-sample">
                    <div className="row">
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">JD number </label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13}  readonly
                                        name="jobcode" value={jobcode} onChange={handleChange} />
                                    </div>
                                    <div className="errorMsg">{errorFields.jobcode}</div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Recruiter ID</label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13}  
                                        name="userid" value={userid} onChange={handleChange}/>
                                    </div>
                                    <div className="errorMsg">{errorFields.userid}</div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Candidate ID</label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13}  
                                        name="candidateid" value={candidateid} onChange={handleChange}/>
                                    </div>
                                    <div className="errorMsg">{errorFields.candidateid}</div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Client ID </label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13}  readonly
                                        name="clientid" value={clientid} onChange={handleChange}/>
                                    </div>
                                    <div className="errorMsg">{errorFields.clientid}</div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Client Name </label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13}  readonly
                                        name="clientname" value={clientname} onChange={handleChange}/>
                                    </div>
                                    <div className="errorMsg">{errorFields.clientname}</div>
                                </Form.Group>
                            </div>
                            
                        </div>

                        <div className="row">
                        <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Candidate Mobile No. </label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={10}  
                                        name="candidatemobileno" value={candidatemobileno} onChange={handleChange}/>
                                    </div>
                                    <div className="errorMsg">{errorFields.candidatemobileno}</div>
                                </Form.Group>
                            </div>
                            
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Candidate Name</label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13} 
                                        name="candidatename" value={candidatename} onChange={handleChange} />
                                    </div>
                                    <div className="errorMsg">{errorFields.candidatename}</div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label"> Designation</label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={50} 
                                        name="designation" value={designation} onChange={handleChange} />
                                    </div>
                                    <div className="errorMsg">{errorFields.designation}</div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Candidate New Work Location </label>
                                    <div className="col-sm-8">
                                    <Form.Control as="textarea" rows="3" col="12"  
                                        name="worklocation" value={worklocation} onChange={handleChange} />
                                    </div>
                                    <div className="errorMsg">{errorFields.worklocation}</div>
                                </Form.Group>
                            </div>
                            
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label"> Offered CTC</label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13}  
                                        name="offeredctc" value={offeredctc} onChange={handleChange}/>
                                    </div>
                                    <div className="errorMsg">{errorFields.offeredctc}</div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-5">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Sign up Rate </label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13} readonly
                                        name="signuprate" value={signuprate} onChange={handleChange} />
                                    </div>
                                    <div className="errorMsg">{errorFields.jdstatus}</div>
                                </Form.Group>
                            </div>
                            
                            <div className="col-md-5">
                                <Form.Group className="row">
                                    <label className="col-sm-5 col-form-label"> Base Invoice Value</label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13}   readonly
                                        name="baseinvoice" value={baseinvoice} onChange={handleChange}
                                        placeholder='offer ctc * signuprate' />
                                    </div>
                                    <div className="errorMsg">{errorFields.baseinvoiceval}</div>
                                </Form.Group>
                            </div>
                        </div>

                        
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Offer Accepted </label>
                                    <div className="col-sm-7">
                                    <select className="form-control" 
                                        name="offeraccepted" value={offeraccepted} onChange={handleChange}>
                                                    <option>Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                 </select>
                                    </div>
                                    <div className="errorMsg">{errorFields.jdstatus}</div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Date of Joining</label>
                                    <div className="col-sm-7">
                                        <Form.Control type="date" maxLength={13} 
                                         
                                        name="dateofjoining" value={dateofjoining} onChange={handleChange} />
                                    </div>
                                    <div className="errorMsg">{errorFields.dateofjoining}</div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label"> Resigned ?</label>
                                    <div className="col-sm-7">
                                    <select className="form-control" 
                                        name="resigned" value={resigned} onChange={handleChange} >
                                                    <option>Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                 </select>
                                    </div>
                                    <div className="errorMsg">{errorFields.jdstatus}</div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Copy of Resignation Recieved ? </label>
                                    <div className="col-sm-7">
                                    <select className="form-control"  
                                        name="copyofresignationrecieved" value={copyofresignationrecieved} onChange={handleChange}>
                                                    <option>Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                 </select>
                                    </div>
                                    <div className="errorMsg">{errorFields.copyofresignationrecieved}</div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label">Recruiter Payout (will be calculated by formula if %) ?</label>
                                    <div className="col-sm-7">
                                        <Form.Control type="text" maxLength={13} 
                                        
                                        name="recruiterpayout" value={recruiterpayout} onChange={handleChange} />
                                    </div>
                                    please discuss with admin, if absolute value.
                                </Form.Group>
                                <div className="errorMsg">{errorFields.userincentive}</div>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="row">
                                    <label className="col-sm-4 col-form-label"> Joined ?</label>
                                    <div className="col-sm-7">
                                    <select className="form-control" 
                                        name="joined" value={joined} onChange={handleChange}>
                                                    <option>Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                 </select>
                                    </div>
                                    <div className="errorMsg">{errorFields.joined}</div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="row">
                                    <label className="col-sm-6 col-form-label">User Comments / Remarks </label>
                                    <div className="col-sm-12">
                                    <Form.Control as="textarea" rows="3" col="12" 
                                        name="comments" value={comments} onChange={handleChange} />
                                    </div>
                                </Form.Group>
                                <div className="errorMsg">{errorFields.comments}</div>
                            </div>
                            
                           
                        </div>

                       {isvisible &&<div className="row">
                            <div className="col-md-6">
                                <Form.Group className="row">
                                    <label className="col-sm-6 col-form-label">TTC's Share </label>
                                    <div className="col-sm-12">
                                    <Form.Control type="text" maxLength={13}  readonly
                                        
                                        name="talenttrackerpayout" value={talenttrackerpayout} onChange={handleChange} />
                                    </div>
                                </Form.Group>
                               
                            </div>

                            <div className="col-md-6">
                                <Form.Group className="row">
                                    <label className="col-sm-6 col-form-label">Expected Payment Receipt Month </label>
                                    <div className="col-sm-12">
                                    <Form.Control type="text" maxLength={13} 
                                        
                                        name="expectedpaymentrecievemonth" value={expectedpaymentrecievemonth} onChange={handleChange} />
                                    </div>
                                </Form.Group>
                               
                            </div>

                            <div className="col-md-6">
                                <Form.Group className="row">
                                    <label className="col-sm-6 col-form-label">Payment Received? </label>
                                    <div className="col-sm-12">
                                    <Form.Control type="text" maxLength={13} 
                                        
                                        name="paymentrecieved" value={paymentrecieved} onChange={handleChange} />
                                    </div>
                                </Form.Group>
                               
                            </div>
                            
                            
                           
                        </div> 
                          }
                        
                        


                    </form>
                    <Button variant="success m-2" onClick={() => saveOffers()}>Save</Button>
                    <Button variant="warning m-2" onClick={() => clearform()}>Clear</Button>
                  </div>
                 </div>   
   
   </>

  )  


}

export default EditOffer