import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import RecruiterOfferlist from './myworkspace/RecruiterOfferlist';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Profile = lazy(() => import('./profile/Profile'));

const Client = lazy(() => import('./client/Client'));
const JDAddition = lazy(() => import('./jd/JdAddition'));
const JDMaster = lazy(() => import('./jd/JDMaster'));
const JDList =lazy(() => import('./jd/JDList'));
const RecruiterList =lazy(() => import('./profile/RecruiterList'));
const RecruiterJDList =lazy(() => import('./myworkspace/RecruiterJDList'));
const Lookups =lazy(() => import('./masters/Lookups'));
const MyWorkspace = lazy(() => import('./myworkspace/MyWorkspace'));
const AllCandidates =lazy(() => import('./candidate/AllCandidates'));
const TemplateList =lazy(() => import('./template/TemplateList'));
const Recruiters =lazy(() => import('./profile/RecruiterMaster'));
const Recruiteroffers = lazy(() => import('./myworkspace/RecruiterOfferlist'));
const Adminoffers =lazy(() => import('./myworkspace/Adminofferlist'));
const Contentedit =lazy(() => import('./shared/ContentEdit'));
const Invoice =lazy(() => import('./jd/Invoice'));
const SearchCandidate =lazy(() => import('./candidatedatabase/SearchCandidate'));



const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));


const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));



const Mdi = lazy(() => import('./icons/Mdi'));


const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));
const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));


const BlankPage = lazy(() => import('./general-pages/BlankPage'));




class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route  path="/dashboard" component={ Dashboard } />
          <Route  path="/profile" component={ Profile } />
          <Route  path="/client" component={ Client } />
          <Route  path="/jdaddition" component={ JDAddition } />
          <Route  path="/jdlist" component={ JDList } />
          <Route  path="/recruiterjdlist" component={ RecruiterJDList } />
          <Route  path="/userlist" component={ RecruiterList } />
          <Route  path="/recruiters" component={ Recruiters } />
          <Route  path="/jdmaster" component={ JDMaster } />
          <Route  path="/lookups" component={ Lookups } />
          <Route  path="/myworkspace" component={ MyWorkspace } />
          <Route  path="/allcandidates" component={ AllCandidates } />
          <Route  path="/templatelist" component={ TemplateList } />
          <Route path="/recruiteroffers" component={ Recruiteroffers } />
          <Route path="/adminofferlist" component={ Adminoffers } />
          <Route  path="/contentedit" component={ Contentedit } />
          <Route  path="/invoice" component={ Invoice } />
          <Route  path="/searchcandidate" component={ SearchCandidate } />
          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
          <Route path="/basic-ui/typography" component={ Typography } />


          <Route path="/form-Elements/basic-elements" component={ BasicElements } />

          <Route path="/tables/basic-table" component={ BasicTable } />


          <Route path="/icons/mdi" component={ Mdi } />


          <Route path="/charts/chart-js" component={ ChartJs } />


          <Route path="/user-pages/login-1" component={ Login } />
          <Route path="/user-pages/register-1" component={ Register1 } />
          <Route path="/user-pages/lockscreen" component={ Lockscreen } />

          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />

          <Route path="/general-pages/blank-page" component={ BlankPage } />


          <Redirect to="/user-pages/login-1" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;