import axios from "axios";
//import config from "../config";
import {APPLICATION_URL} from '../urlconfig';

let config = {
 
  
   PROFILEURL :{
     
      GET_PERSONAL_INFO : "api/v1/user/get",
      UPDATE_PERSONAL_INFO:"api/v1/user/update",

      GET_BANK_INFO : "api/v1/bankdetails/get",
      SAVE_BANK_INFO :"api/v1/bankdetails/save",
      UPDATE_BANK_INFO:"api/v1/bankdetails/update",

      GET_WORK_INFO : "api/v1/workinformation/get",
      SAVE_WORK_INFO :"api/v1/workinformation/save",
      UPDATE_WORK_INFO:"api/v1/workinformation/update",

      ASSIGNED_INFO:"api/v1/assignedinformation/get",
      UPDATE_ASSIGNED_INFO:"api/v1/assignedinformation/update",
      LOOKUPS :"api/v1/lookups/get",
      SAVE_LOOKUPS :"api/v1/lookups/save",
      UPDATE_LOOKUPS :"api/v1/lookups/update",
      LIST_USERS :"api/v1/listusers/get",
      CHANGE_PASSWORD:"api/v1/user/changepassword",
      UPDATE_USER_STATUS:"api/v1/user/updateuserstatus",
      RECRUITER_JOB_CODE:"api/v1/userjobcode/save",
      GET_RECRUITER_JOB_CODE:"api/v1/userjobcode/get",
      UPDATE_RECRUITER_JOB_CODE :"api/v1/userjobcode/update",
      USER_DELETE:"api/v1/user/deleteuser",
      GET_EMAIL_SETUP:"api/v1/emailsetup/get",
      SAVE_EMAIL_SETUP:"api/v1/emailsetup/save",
      UPDATE_EMAIL_SETUP:"api/v1/emailsetup/update",
      GET_USER_JOB :"api/v1/userjobcode/getjobs"
  
   }
      
     
  
  }

  export const GetuserJobID =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.GET_USER_JOB, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}
  export const UpdateEmailSetup =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.UPDATE_EMAIL_SETUP, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const SaveEmailSetup =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.SAVE_EMAIL_SETUP, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const GetEmailSetup =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.GET_EMAIL_SETUP, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const updateRecruiterJobInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.UPDATE_RECRUITER_JOB_CODE, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const deleteUserInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.USER_DELETE, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const GetRecruiterJobs =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.GET_RECRUITER_JOB_CODE, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const LockUserJD =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.RECRUITER_JOB_CODE, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

  export const AlluserInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.LIST_USERS, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const ChangepasswordbyAdmin =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.CHANGE_PASSWORD, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const UpdateUserStatus =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.UPDATE_USER_STATUS, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const personalInfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.GET_PERSONAL_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const updatePersonalInfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.UPDATE_PERSONAL_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const BankInfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.GET_BANK_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const SaveBankDetails =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.SAVE_BANK_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const UpdateBankDetails =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.UPDATE_BANK_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const WorkInfomation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.GET_WORK_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const SaveWorkinfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.SAVE_WORK_INFO, 
        fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const UpdateWorkInfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.UPDATE_WORK_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const Assignedinfo =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.ASSIGNED_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const LookupInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.LOOKUPS, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const SaveLookupInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.SAVE_LOOKUPS, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const UpdateLookupInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.UPDATE_LOOKUPS, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}

export const UpdateAssignedInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken')

    }
return await  axios.post(APPLICATION_URL +"/"+config.PROFILEURL.UPDATE_ASSIGNED_INFO, fields,{ 'headers': headers })
    .then((res) => {
        return res.data;
    }).catch((error) => {
        window.location = "/";
        return error;
    });


}