import axios from "axios";
//import config from "../config";
import {APPLICATION_URL} from '../urlconfig';

let config = {
 
  
   RECRUITERAPIURL :{
     
      LOGIN_URL : "api/auth/signin",
      SIGN_UP_URL :"api/auth/signup",
      FORGOT_PASSWORD :"api/auth/forgotpassword",
      SAVE_CONTENT :"api/v1/content/save",
      GET_CONTENT:"api/v1/content/get",
      UPDATE_CONTENT:"api/v1/content/update"
  
   }
      
     
  
  }

  export const getContentInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
    return await  axios.post(APPLICATION_URL +"/"+config.RECRUITERAPIURL.GET_CONTENT, fields,
    { 'headers': headers })
        .then((res) => {
            return res.data;
        }).catch((error) => {
           // window.location = "/";
            return error;
        });
    
    
    }

  export const updateContentInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
    return await  axios.post(APPLICATION_URL +"/"+config.RECRUITERAPIURL.UPDATE_CONTENT, fields,
    { 'headers': headers })
        .then((res) => {
            return res.data;
        }).catch((error) => {
           // window.location = "/";
            return error;
        });
    
    
    }

  export const saveContentInformation =async (fields)=>{
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': sessionStorage.getItem('accessToken'),
        
       

    }
    return await  axios.post(APPLICATION_URL +"/"+config.RECRUITERAPIURL.SAVE_CONTENT, fields,
    { 'headers': headers })
        .then((res) => {
            return res.data;
        }).catch((error) => {
           // window.location = "/";
            return error;
        });
    
    
    }

  
export const ForgotUserPassword =async (fields)=>{
    return await  axios.post(APPLICATION_URL +"/"+config.RECRUITERAPIURL.FORGOT_PASSWORD, fields)
        .then((res) => {
            return res.data;
        }).catch((error) => {
            window.location = "/";
            return error;
        });
    
    
    }


export const UserLogin =async (fields)=>{
return await  axios.post(APPLICATION_URL +"/"+config.RECRUITERAPIURL.LOGIN_URL, fields)
    .then((res) => {
        return res.data;
    }).catch((error) => {
       // window.location = "/";
        return error;
    });


}

export const Signup =async (fields)=>{
    return await  axios.post(APPLICATION_URL +"/"+config.RECRUITERAPIURL.SIGN_UP_URL, fields)
        .then((res) => {
            return res.data;
        }).catch((error) => {
           // window.location = "/";
            return error;
        });
    
    
    }