
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button } from 'react-bootstrap';


import { Form } from 'react-bootstrap';
import { AppContext } from '../context';

const DateFilter = (props) => {

    const fields = {
        fromdate: '',
        todate: ''
     }

    const [formfields, setFromFields] = useState(fields);
    const [industrylist, setIndustryList] = useState([]);
    const [statelist, setStateList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);


    const {
        fromdate, todate
    } = formfields

    useEffect(() => {
        setUpInitialServices();

    }, []);

    const setUpInitialServices = async () => {

       
        if (localStorage.getItem('filtersave') != null) {
            setFromFields(JSON.parse(localStorage.getItem('filtersave')));
        }


    }
   
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFromFields((prevState) => ({
            ...prevState,
            [name]: value,
        }))


    }
    localStorage.setItem('fieldforms', JSON.stringify(formfields));

    const clearfields =async() =>{
        setFromFields(fields);
    }

    return (
        <>
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">From Date </label>
                            <div className="col-sm-7">
                                <Form.Control type="date" maxLength={13} name="fromdate"
                                    value={fromdate} onChange={handleChange} />
                            </div>

                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="row">
                            <label className="col-sm-4 col-form-label">To Date</label>
                            <div className="col-sm-7">
                                <Form.Control type="date" maxLength={13} name="todate"
                                    value={todate} onChange={handleChange} />
                            </div>
                        </Form.Group>
                    </div>
                   
                </div>


              
                <Button variant="warning m-2" onClick={clearfields}>Reset</Button>

        </>
    )

}
export default DateFilter