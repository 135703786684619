import React, { Component } from 'react';
import { Dropdown, Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import '../shared/Navbar.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChangepasswordbyAdmin } from '../profile/Profile.service';
import PasswordUpdate from '../profile/PasswordUpdate';
import Unsubscribe from '../candidate/Unsubscribe';
import UserContent from './UserContent';
import RecruiterAgreement from '../profile/RecruiterAgreement';


class Navbar extends Component {

  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.showModal = this.showModal.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.UserPasswordUpdate = this.UserPasswordUpdate.bind(this);
    this.openwindow = this.openwindow.bind(this);
    this.unSubscribe = this.unSubscribe.bind(this);
    this.opencontent = this.opencontent.bind(this);
    this.openwindow1 = this.openwindow1.bind(this);
    this.openwindow2 = this.openwindow2.bind(this);
    this.openwindow3 = this.openwindow3.bind(this);
    this.openwindow4 = this.openwindow4.bind(this);
    this.viewagreement = this.viewagreement.bind(this);
    this.openwindow5 = this.openwindow5.bind(this);
  }

  state = {
    username: '',
    lgShow: false,
    fields: { password: '', confirmpassword: '' },
    errors: {},
    pagetype: '',
    pagetitle: ''

  };

  viewagreement(e) {
    e.preventDefault();
    this.setState({ lgShow: true })
    this.setState({ pagetype: 'AGREEMENT' });
  }

  opencontent(type) {
    this.setState({ lgShow: true })
    this.setState({ pagetype: 'CONTENT' });
    localStorage.setItem('contentype', type);
  }

  openwindow(e) {
    e.preventDefault();
    window.open('https://youtu.be/hmNFjc8lJJo');
  }

  openwindow1(e) {
    e.preventDefault();
    window.open('https://www.youtube.com/watch?v=ENc0GePiQqQ');
  }
  openwindow2(e) {
    e.preventDefault();
    window.open('https://youtu.be/QpI2uXq9dgU ');
  }
  openwindow3(e) {
    e.preventDefault();
    window.open('https://youtu.be/I3eHcPoevY0');
  }
  openwindow4(e) {
    e.preventDefault();
    window.open('https://youtu.be/j1kRo3DLdCY');
  }

  openwindow5(e){
    e.preventDefault();
    window.open('https://www.youtube.com/watch?v=Ad-JVa7-ZBE');
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });

  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    console.log(this.state.fields);

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (typeof fields["password"] !== "undefined") {
      if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }
    if (fields["password"] != fields["confirmpassword"]) {
      formIsValid = false;
      errors["confirmpassword"] = "*New password and confirm password should be same.";
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  logout(e) {
    // e.preventDefault();
    this.props.history.push('/');
  }

  changePassword(e) {
    e.preventDefault();
    this.setState({ pagetitle: 'Change Password' });
    this.setState({ pagetype: 'CHANGEPASSWORD' });
    this.setState({ lgShow: true })
  }

  unSubscribe(e) {
    e.preventDefault();
    this.setState({ pagetitle: 'Unsubscribe' });
    this.setState({ pagetype: 'UNSUBSCRIBE' });
    this.setState({ lgShow: true })
  }

  UserPasswordUpdate(e) {
    e.preventDefault();
    if (this.validateForm()) {
      this.PasswordUpdateContinue();
    }
  }

  PasswordUpdateContinue = async () => {
    const reqdata = {
      id: sessionStorage.getItem('userId'),
      password: this.state.fields.password,
      changedby: sessionStorage.getItem('userId')
    }

    const retResponse = await ChangepasswordbyAdmin(reqdata);
    if (retResponse.data == 'Success') {
      this.setState({ lgShow: false })
      toast(retResponse.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      let fields = {};
      fields["confirmpassword"] = "";
      fields["password"] = "";
      this.setState({ fields: fields });
      this.props.history.push('/');
    } else {
      toast('Failed to change your password, Please contact admin', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  showModal(e) {
    this.setState({ lgShow: false })
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  componentDidMount() {
    this.setState({ username: sessionStorage.getItem('displayName') });

  }
  render() {
    return (


      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">

        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={require('../../assets/images/logo.jpeg')} alt="logo" /></Link>
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo.jpeg')} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
            <span className="mdi mdi-menu"></span>
          </button>

          <ul className="navbar-nav navbar-nav-right">

            <li className="nav-item nav-profile">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link"  onClick={this.viewagreement}>
                  <div className="nav-profile-text" >
                    <p className="mb-1 text-black"><Trans>View Agreement</Trans></p>
                  </div>
                </Dropdown.Toggle>
              </Dropdown>
            </li>
            <li className="nav-item nav-profile">
              <Dropdown alignRight>


                <Dropdown.Toggle className="nav-link">

                  <div className="nav-profile-text">
                    <p className="mb-1 text-black"><Trans>{this.state.username}</Trans></p>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown">
                  <Dropdown.Item href="!#" onClick={this.changePassword}>
                    <i className="mdi mdi-cached mr-2 text-success"></i>
                    <Trans>Change Password</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item href="!#" onClick={this.unSubscribe} >
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Un Subscribe</Trans>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={this.opencontent.bind(this, "BP")}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Best Practices in Candidate Sourcing</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.opencontent.bind(this, "GD")}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Guidelines to use platform</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.opencontent.bind(this, "DD")}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Do's & Dont's</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.opencontent.bind(this, "TIP")}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Recruiter Tips</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.opencontent.bind(this, "FAQ")}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>FAQ ?</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.opencontent.bind(this, "FC")}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Freelancing Process(SOP)</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.opencontent.bind(this, "QG")}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Quick Guide</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item href="!#" onClick={this.logout}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Signout</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </li>



            <li className="nav-item nav-profile">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link">

                  <div className="nav-profile-text">
                    <p className="mb-1 text-black"><Trans>Traning Videos</Trans></p>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown">
                  <Dropdown.Item href="!#" onClick={this.openwindow}>
                    <i className="mdi mdi-cached mr-2 text-success"></i>
                    <Trans>Application Walkthrough Video</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item href="!#" onClick={this.openwindow1} >
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Payout Video</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item href="!#" onClick={this.openwindow2} >
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Job Status Change Video</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item href="!#" onClick={this.openwindow3} >
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Online CVs submission to Clients</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item href="!#" onClick={this.openwindow4} >
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>How to add candidates to tracker sheet & download tracker</Trans>
                  </Dropdown.Item>

                  <Dropdown.Item href="!#" onClick={this.openwindow5} >
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Candidate Database Video</Trans>
                  </Dropdown.Item>


                </Dropdown.Menu>
              </Dropdown>

            </li>



          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>

        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={this.showModal}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.pagetitle}</Modal.Title>
          </Modal.Header>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />

          <Modal.Body>
            {
              this.state.pagetype === 'CHANGEPASSWORD' ? (
                <PasswordUpdate />
              ) : this.state.pagetype === 'UNSUBSCRIBE' ? (
                <Unsubscribe />
              ) : this.state.pagetype === 'CONTENT' ? (
                <UserContent />
              ) : this.state.pagetype === 'AGREEMENT' ? (
            <RecruiterAgreement/>
            )
            : null

                                    }

          </Modal.Body>

          <Modal.Footer>
            <Button variant="light mb-" onClick={this.showModal}>Close</Button>
          </Modal.Footer>
        </Modal>

      </nav>
    );
  }



}


export default Navbar;
