import React, {  useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { AgreementContent } from './AgreementContent';

function RecruiterAgreement() {

    

    const componentRef = useRef();

    return (
        <>
           <p></p>
            <div className="row" >
                <div className="col-md-12">
                    <div className="row">
                    <p></p>
                        <div className="col-sm-12">
                            <ReactToPrint
                                trigger={() => <button>Print this out!</button>}
                                content={() => componentRef.current}
                            />
                            <AgreementContent ref={componentRef} />
                        </div>



                    </div>

                </div>

            </div>
        </>
    )

}

export default RecruiterAgreement